//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

$ng-modal-padding: 3em;
$ng-modal-image-height: 135px;

// The portal element of a modal element
// ( = the outer element should span the entire view )
.op-modal--portal {
  // overlay
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  text-align: left;
  z-index: 9000;

  // Center modal content vertically and horizontally
  display: flex;
  align-items: center;
  justify-content: center;

  &.-transparent {
    background: transparent; } }

// The inner modal element containing header, body, and footer
// This is placed within --portal
.op-modal--modal-container {
  transition: opacity 0.25s ease;
  background: var(--body-background);
  position: relative;
  padding: 5px $ng-modal-padding / 2;
  min-width: 200px;
  max-width: 60vw;
  overflow-y: auto;

  @include styled-scroll-bar;

  &.-wide {
    min-width: 75vw;
    min-height: 40vh; }

  &.-slim {
    min-width: 25vw;
    min-height: 20vh; } }

.op-modal--modal-header {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding: 0 45px 0 0;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--light-gray);

  * {
    height: var(--header-height);
    line-height: var(--header-height); }

  h2, h3 {
    border: none;
    @include text-shortener;
    margin: 0; } }

.op-modal--modal-body {
  margin: 1em 0;
  max-height: calc(100vh - var(--header-height) - var(--header-height));
  overflow: auto;
  @include styled-scroll-bar;

  &.-formattable {
    p:last-of-type {
      margin-bottom: 0px; } } }

.op-modal--modal-footer {
  margin: 1.5rem 0;

  button {
    margin-bottom: 0;

    &:last-of-type {
      margin-right: 0; } } }

.op-modal--modal-close-button {
  position: absolute;
  right: 25px;
  top: 4px;
  cursor: pointer;
  color: var(--body-font-color);
  &:hover {
    text-decoration: none;
    color: var(--content-link-color); } }

// Highlighted modal style matching header menu
.op-modal--modal-container.-highlight {
  padding: 0;

  .op-modal--modal-body,
  .op-modal--modal-footer {
    padding: 0 1.5rem; }

  // Overridden header styles
  .op-modal--modal-header {
    padding-left: 1.5rem;
    background-color: var(--header-bg-color);
    border-bottom-width: var(--header-border-bottom-width);
    border-bottom-color: var(--header-border-bottom-color);

    * {
      color: var(--header-item-font-color); } }

  .op-modal--modal-close-button {
    top: 0;
    color: var(--header-item-font-color); }

  .avatar {
    margin-right: 0.5rem; }

  &.-danger-zone {
    .op-modal--modal-header {
      background-color: var(--content-form-danger-zone-bg-color);
      border-bottom-color: var(--content-form-danger-zone-bg-color); }

    .op-modal--modal-close-button {
      color: var(--content-form-danger-zone-font-color); } } }


// Specific styles for columns-modal
.columns-modal--content {
  margin-bottom: 15px;
  label {
    display: inline;
    padding-right: 30px; } }

.ee-relation-columns-upsale {
  margin-top: -0.7rem;
  padding-bottom: 1rem; }

// Specific styles for export-modal
ul.export-options {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 20px 0;
  list-style-type: none;
  li {
    flex: 1 1 calc(33% - 40px);  // line break after third element
    margin: 20px;
    text-align: center;
    a {
      cursor: pointer;
      text-decoration: none;
      color: var(--body-font-color);
      font-weight: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
      &:hover, &:active {
        text-decoration: none;
        color: var(--body-font-color); }
      span.export-label {
        display: block;
        padding: 10px 0 0 0; } } } }

// Legacy styles for jQuery UI modal
.ui-dialog.ui-widget {
  overflow: visible; }

// remove iframe border explicitly because Firefox does add it for some reason
#modalIframe {
  border: 0; }

// Hide modal content forwarded from rails
// for the dynamic modal
.modal-wrapper--content {
  display: none; }


@include breakpoint(680px down) {
  .wp-table--configuration-modal,
  .op-modal--modal-container {
    width: 90vw;
    max-width: unset; } }
