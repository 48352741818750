.avatars--current-gravatar,
.avatars--current-local-avatar {
  display: inline-block;
  padding: 10px;
  border: 1px solid #aaa;
  img {
    width: 64px;
    height: 64px; } }

.avatars--local-avatar-preview {
  width: 128px;
  height: 128px;
  border-radius: 50%; }

.avatars--current-gravatar {
  margin-bottom: 20px; }

.avatars--error-pane {
  color: var(--content-form-error-color);
  font-weight: bold; }

.avatar--fallback,
.avatar-default {
  margin-right: 5px; }

.user-avatar--multi-line {
  display: block;
  margin-bottom: 2px; }
